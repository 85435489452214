import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

// const ls = new SecureLS({
//   isCompression: false
// });

export default createStore({
  plugins: [
    createPersistedState({
      key: "ECOBLOX-ADMIN",
      // storage: {
      //   // getItem: (key) => ls.get(key),
      //   // setItem: (key, value) => ls.set(key, value),
      //   // removeItem: (key) => ls.remove(key)
      // }
    }),
  ],
  state: {
    authentication: {
      accessToken: null,
      expiresIn: 0,
      name: "",
    },
    rememberMe: {
      email: "",
      password: "",
      rememberMe: false,
    },
    email: "",
  },
  getters: {
    isAuthenticated(state): boolean {
      return state.authentication.accessToken !== null;
    },
    getAuthenticationAccessToken(state): boolean | null {
      return state.authentication.accessToken;
    },
    getAuthenticationName(state): string {
      return state.authentication.name;
    },
    isRememberMeSet(state): boolean {
      return state.rememberMe.rememberMe;
    },
    getEmail(state): string {
      return state.email;
    },
  },
  mutations: {
    setAuthentication(state, data) {
      state.authentication = data;
    },
    setRememberMe(state, data) {
      state.rememberMe = data;
    },
    setEmail(state, data) {
      state.email = data;
    },
    setLogout(state) {
      state.authentication = {
        accessToken: null,
        expiresIn: 0,
        name: "",
      };
      state.email = "";
    },
  },
  actions: {
    setAuthentication({ commit }, data) {
      commit("setAuthentication", data);
    },
    setRememberMe({ commit }, data) {
      commit("setRememberMe", data);
    },
    setEmail({ commit }, data) {
      commit("setEmail", data);
    },
    setLogout({ commit }) {
      commit("setLogout");
    },
  },
});
