export interface CommonResponseIndex {
  data: any;
  total: number;
  totalPages: number;
  message: string;
  errors: any;
  status: string;
}

export class CommonResponseIndex implements CommonResponseIndex {
  data: any;
  total: number;
  totalPages: number;
  message: string;
  errors: any;
  status: string;

  constructor() {
    this.data = null;
    this.total = 0;
    this.totalPages = 0;
    this.message = "";
    this.errors = null;
    this.status = "";
  }
}
