// Vendors
import { AxiosResponse } from "axios";

// Api
import Api from "@/services/api/index";

// Models
import { CommonResponseIndex } from "@/models/common/response";

// Services
import { setNotification } from "@/services/app";
import { getStatus } from "@/services/app";

// Store
import Store from "@/store";

const getRequest = async (options: { uri: string; formData?: any; isProtected?: boolean; successNotification?: string; isSuccessNotificationVisible?: boolean; isErrorNotificationVisible?: boolean }): Promise<CommonResponseIndex> => {
  let response: CommonResponseIndex = new CommonResponseIndex();

  await Api({
    method: "GET",
    headers: {
      Authorization: `${options.isProtected ? Store.getters.getAuthenticationAccessToken : ""}`,
    },
    url: `${options.uri}`,
    params: options.formData,
  })
    .then((r: AxiosResponse) => {
      response = {
        data: r.data.data ?? null,
        total: r.data.total ?? 0,
        totalPages: r.data.last_page ?? 0,
        message: options.successNotification ?? "",
        errors: null,
        status: getStatus(r.status),
      };

      if (options.isSuccessNotificationVisible) {
        setNotification(response.message, response.status);
      }
    })
    .catch((e) => {
      response = {
        data: null,
        total: 0,
        totalPages: 0,
        message: e.response.data.message ?? "",
        errors: e.response.data.errors ?? null,
        status: getStatus(e.response.status),
      };

      if (options.isErrorNotificationVisible && response.errors && response.errors.length === 0) {
        setNotification(response.message, response.status);
      } else if (options.isErrorNotificationVisible && response.errors) {
        Object.entries(response.errors).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value.forEach((val: any) => {
            setTimeout(() => {
              setNotification(`${val}`, "warning");
            }, 50);
          });
        });
      } else {
        setNotification("An error occurred", response.status);
      }
    });

  return response;
};

const postRequest = async (options: { uri: string; formData?: any; isProtected?: boolean; successNotification?: string; isSuccessNotificationVisible?: boolean; isErrorNotificationVisible?: boolean }): Promise<CommonResponseIndex> => {
  let response: CommonResponseIndex = new CommonResponseIndex();

  await Api({
    method: "POST",
    headers: {
      Authorization: `${options.isProtected ? Store.getters.getAuthenticationAccessToken : ""}`,
    },
    url: `${options.uri}`,
    data: options.formData,
  })
    .then((r: AxiosResponse) => {
      response = {
        data: r.data.data ?? null,
        total: r.data.total ?? 0,
        totalPages: r.data.last_page ?? 0,
        message: options.successNotification ?? "",
        errors: null,
        status: getStatus(r.status),
      };

      if (options.isSuccessNotificationVisible) {
        setNotification(response.message, response.status);
      }
    })
    .catch((e) => {
      response = {
        data: null,
        total: 0,
        totalPages: 0,
        message: e.response.data.message ?? "",
        errors: e.response.data.errors ?? null,
        status: getStatus(e.response.status),
      };

      if (options.isErrorNotificationVisible && response.errors && response.errors.length === 0) {
        setNotification(response.message, response.status);
      } else if (options.isErrorNotificationVisible && response.errors) {
        Object.entries(response.errors).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value.forEach((val: any) => {
            setTimeout(() => {
              setNotification(`${val}`, "warning");
            }, 50);
          });
        });
      } else {
        setNotification("An error occurred", response.status);
      }
    });

  return response;
};

const putRequest = async (options: { uri: string; formData?: any; isProtected?: boolean; successNotification?: string; isSuccessNotificationVisible?: boolean; isErrorNotificationVisible?: boolean }): Promise<CommonResponseIndex> => {
  let response: CommonResponseIndex = new CommonResponseIndex();

  await Api({
    method: "PUT",
    headers: {
      Authorization: `${options.isProtected ? Store.getters.getAuthenticationAccessToken : ""}`,
    },
    url: `${options.uri}`,
    data: options.formData,
  })
    .then((r: AxiosResponse) => {
      response = {
        data: r.data.data ?? null,
        total: r.data.total ?? 0,
        totalPages: r.data.last_page ?? 0,
        message: options.successNotification ?? "",
        errors: null,
        status: getStatus(r.status),
      };

      if (options.isSuccessNotificationVisible) {
        setNotification(response.message, response.status);
      }
    })
    .catch((e) => {
      response = {
        data: null,
        total: 0,
        totalPages: 0,
        message: e.response.data.message,
        errors: e.response.data.errors ?? null,
        status: getStatus(e.response.status),
      };

      if (options.isErrorNotificationVisible && response.errors && response.errors.length === 0) {
        setNotification(response.message, response.status);
      } else if (options.isErrorNotificationVisible && response.errors) {
        Object.entries(response.errors).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value.forEach((val: any) => {
            setTimeout(() => {
              setNotification(`${val}`, "warning");
            }, 50);
          });
        });
      } else {
        setNotification("An error occurred", response.status);
      }
    });

  return response;
};

const deleteRequest = async (options: { uri: string; formData?: any; isProtected?: boolean; successNotification?: string; isSuccessNotificationVisible?: boolean; isErrorNotificationVisible?: boolean }): Promise<CommonResponseIndex> => {
  let response: CommonResponseIndex = new CommonResponseIndex();

  await Api({
    method: "DELETE",
    headers: {
      Authorization: `${options.isProtected ? Store.getters.getAuthenticationAccessToken : ""}`,
    },
    url: `${options.uri}`,
    data: options.formData,
  })
    .then((r: AxiosResponse) => {
      response = {
        data: r.data.data ?? null,
        total: r.data.total ?? 0,
        totalPages: r.data.last_page ?? 0,
        message: options.successNotification ?? "",
        errors: null,
        status: getStatus(r.status),
      };

      if (options.isSuccessNotificationVisible) {
        setNotification(response.message, response.status);
      }
    })
    .catch((e) => {
      response = {
        data: null,
        total: 0,
        totalPages: 0,
        message: e.response.data.message,
        errors: e.response.data.errors ?? null,
        status: getStatus(e.response.status),
      };

      if (options.isErrorNotificationVisible && response.errors && response.errors.length === 0) {
        setNotification(response.message, response.status);
      } else if (options.isErrorNotificationVisible && response.errors) {
        Object.entries(response.errors).forEach(([key, value]) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value.forEach((val: any) => {
            setTimeout(() => {
              setNotification(`${val}`, "warning");
            }, 50);
          });
        });
      } else {
        setNotification("An error occurred", response.status);
      }
    });

  return response;
};

export { getRequest, postRequest, putRequest, deleteRequest };
