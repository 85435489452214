// Vendors
import { ElNotification } from "element-plus";

// Store
import store from "@/store/index";

const currencyFormatterUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const currencyFormatterBTC = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "BTC",
  maximumSignificantDigits: 8,
});

const getStatus = (status: number): string => {
  if (status >= 200 && status < 300) {
    return "success";
  }

  if (status >= 400 && status < 500) {
    return "warning";
  }

  return "error";
};

const setNotification = (message: string, type: any): void => {
  ElNotification({
    title: "",
    message: message,
    type: type,
  });
};

const getPermissions = (): Array<string> => {
  const accessToken = store.getters.getAuthenticationAccessToken;

  if (accessToken) {
    const base64Url = accessToken.substring(7).split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const parsedAccessToken = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c: string) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(parsedAccessToken).permissions;
  }

  return [];
};

const getPermissionStatus = (permission: string): boolean => {
  const permissions = getPermissions();

  const hasPermission = permissions.find((item: string) => {
    return item === permission;
  });

  return !!hasPermission;
};

export { currencyFormatterUSD, currencyFormatterBTC, getStatus, setNotification, getPermissions, getPermissionStatus };
